import React, {useState, useEffect} from 'react'
import { changePassword } from '../../../lib/data'
import {toast} from 'react-toastify';

import hide from '../../../images/hide.png';
import show from '../../../images/show.png';


const Password = () => {
  const [data, setData] = useState({
      old_password: "",
      new_password: "",
      confirm_password: ""
  });
  const [passwordValid, setPasswordValid] = useState(false);
  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
console.log(passwordValid)
  useEffect(()=>{
    const passwordWarning = document.getElementById("password-warning");
    if(data.new_password!==data.confirm_password){
        passwordWarning.innerHTML = "<p>Password must match!</p>";
        setPasswordValid(false);
    } else {
        passwordWarning.innerHTML = "";
        setPasswordValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.confirm_password])

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })

    const passwordWarning = document.getElementById("password-warning");
      var hasUpperCase = /[A-Z]/.test(e.target.value);
      var hasLowerCase = /[a-z]/.test(e.target.value);
      var hasNumbers = /\d/.test(e.target.value);
      if(e.target.name==="new_password"){
          if (hasUpperCase && hasLowerCase && hasNumbers) {
              passwordWarning.innerHTML = "";
              setPasswordValid(true);
          } else {
              passwordWarning.innerHTML = "<p>Password must be a minimum 8 characters and contain at least one uppercase letter, one lower case letter and a number</p>";
              setPasswordValid(false);
          } 
      }
  }

  const doSubmit = (e) => {
    e.preventDefault();
    let payload = JSON.stringify(data)
    if(passwordValid){
      changePassword(payload)
        .then((response) => response.data)
        .then((res) => {
            if (res.status === true) {
                toast.success(res.message);
                document.getElementById("my-form").reset()
            } else {
                toast.error(res.message)
            }
        })
        .catch((err) => {
            if(err.response){
                toast.error(err.response.data.message);
            } else{
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
            }
        });
    } else {
      toast.error('Please make sure your password is valid!')
    }
  }
  return (
    <div className='settings_profile'>
      <div className='settings_profile-top'>
        <h5>Password and Pin</h5>
        <p>Update your personal details here.</p>
      </div>
      <form onSubmit={doSubmit} id="my-form">
        <div className='settings_profile-item'>
          <div className='settings_profile-item-left'>
            <p className='text1'>Current Password</p>
          </div>
          <div className='settings_profile-item-right'>
              <input type={showOld ? "text":'password'} required name='old_password' onChange={handleChange} />
              <img src={showOld ? show:hide} alt="" className='settings_profile-item-right-eye' onClick={()=>setShowOld(!showOld)} />
          </div>
        </div>
        <div className='settings_profile-item'>
          <div className='settings_profile-item-left'>
            <p className='text1'>New Password</p>
          </div>
          <div className='settings_profile-item-right'>
              <input type={showNew ? "text":'password'} required name='new_password' onChange={handleChange} />
              <img src={showNew ? show:hide} alt="" className='settings_profile-item-right-eye' onClick={()=>setShowNew(!showNew)} />
          </div>
        </div>
        <div className='settings_profile-item'>
          <div className='settings_profile-item-left'>
            <p className='text1'>Confirm New Password</p>
          </div>
          <div className='settings_profile-item-right' style={{display:'block'}}>
              <input type={showConfirm ? "text":'password'} required name='confirm_password' onChange={handleChange} />
              <p id="password-warning" style={{color:'red', marginBottom:'-10px'}} className='text-right'></p>
              <img src={showConfirm ? show:hide} alt="" className='settings_profile-item-right-eye' onClick={()=>setShowConfirm(!showConfirm)} />
          </div>
        </div>
        <div className='settings_profile-buttons'>
          <button type="submit" className='submit'>Reset Password</button>
        </div>
      </form>
    </div>
  )
}

export default Password