import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


function Request ({userData}) {
    const history = useNavigate();
    const [activateBtn, setActivateBtn] = useState(false)

    useEffect(()=>{
        if(
            userData?.user?.verification?.address_verification?.status==='verified' &&
            userData?.user?.verification?.bank_verification?.status==='verified' &&
            userData?.user?.verification?.employment_verification?.status==='verified' &&
            userData?.user?.verification?.identity_verification?.status==='verified'
        ) {
            setActivateBtn(true)
        }
    },[userData])

    const goTo = () => {
        if(activateBtn){
            history("/loans/request")
        } else {
            toast.info('Please complete your verification first!');
            history("/");
        }
    };

    return(
        <div className='home_box2'>
            <div className='home_box2-top'>
                <h5>Request a loan</h5>
            </div>
            <div className='home_box2-main' style={{marginTop:'-20px'}}>
                <p>Request a loan and get money in your preferred bank account in 5mins!</p>
            </div>
            <div className='home_box2-request'>
                <button className={activateBtn ? 'home_box2-request-active':'home_box2-request-inactive'} onClick={goTo}>Request a loan</button>
            </div>
        </div>
    )
}

export default Request;