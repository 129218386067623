import { useEffect, useState } from "react";
import selectArrow from "../../images/svg/select-arrow.svg";


function  TypeSelect ({placeholder, options, selectChange, arrayType, filled}) {
    const [selected, setSelected] = useState("");
    const [showOptions, setShowOptions] = useState(false);
    const [filtering, setFiltering] = useState([]);

    useEffect(()=>{
        setFiltering(options)
    },[options])

    const filterItems = (value) => {
        if (value) { 
            const filteredList = options.filter(item => item.name.toString().toLowerCase().startsWith(value.toLowerCase()));
            setFiltering(filteredList)
        } else (
            setFiltering(options)
        )
    }

    return(
        <div className="select">
            <input onClick={()=>setShowOptions(!showOptions)} 
                onChange={(e) => {
                    setSelected(e.target.value);
                    setShowOptions(true);
                    filterItems(e.target.value);
                }}
                value={selected} 
                placeholder={placeholder} 
                style={!filled ? {borderColor:'#FDA29B'}:{borderColor:'#C4C4C4'}}
            />
            { showOptions &&
                <div className="select_options">
                    {
                        arrayType === "countryObject" && filtering.length ?
                        filtering?.map((item, idx)=>(
                            <p 
                                className={selected===item.name?"select_options_selected":""} 
                                key={idx}
                                onClick={()=>{
                                    setSelected(item.name);
                                    setShowOptions(false);
                                    selectChange(item.name, item.id)
                                }}
                            >
                                {item.name}
                            </p>
                        )) :
                        options?.map((item, idx)=>(
                            <p 
                                className={selected===item.name?"select_options_selected":""} 
                                key={idx} 
                                onClick={()=>{
                                    setSelected(item.name); 
                                    setShowOptions(false); 
                                    selectChange(item.name, item.id)
                                }}
                            >
                                {item.name}
                            </p>
                        ))
                    }
                </div>
            }
            <img src={selectArrow} alt="arrow" className="select_arrow-type" />
        </div>
    )
}

export default TypeSelect;