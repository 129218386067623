import { useEffect, useState } from 'react';
import OTPInput from "otp-input-react";
import { Link, useParams } from 'react-router-dom';
import {toast} from "react-toastify";
import { RequestCode, SendVerificationCode } from '../../lib/auth';

import back from '../../images/svg/auth/back.svg';
import mark from '../../images/svg/auth/mark.svg';
import message from '../../images/svg/auth/message.svg';
import loader from '../../images/svg/loader.svg';


function Verification () {
    const params = useParams();
    const [success, setSuccess] = useState(false);
    const [OTP, setOTP] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);

    useEffect(()=>{
        requestOTP()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const verifyOTP = (e) => {
        setDisableBtn(true);
        e.preventDefault()
        let payload = JSON.stringify({
            otp_code: OTP,
            mobile_number: params.mobile_number
        });
        SendVerificationCode(payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                setDisableBtn(false);
                if (res.status === true) {
                    toast.success(res.message);
                    setSuccess(true);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setDisableBtn(false);
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const requestOTP = () => {
        let payload = JSON.stringify({
            type: 'mobile_number',
            mobile_number: params.mobile_number
        });

        RequestCode(payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                if (res.status === true) {
                    toast.success(res.message);
                    setOTP(res.data.otp_code);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }
    
    return(
        <div className="auth_section1-content">
            <div className="text-center">
                <img src={success?mark:message} alt="" className="auth_section1-content-topImg" />
                <h3>{success?'Phone Number verified':'Phone Verification'}</h3>
                <p className="auth_section1-content-topP">
                    {
                        success ?
                        'Your email has been succesfully verified'
                        :
                        `We sent a verification link to ${params.mobile_number}`
                    }
                </p>
            </div>
            {
                success ?
                <form>
                    <Link to={`/auth/create-pin/${params.mobile_number}`} className='link'><button type="button" >Continue</button></Link>
                </form>
                :
                <form onSubmit={verifyOTP}>
                    <OTPInput 
                        value={OTP} 
                        onChange={setOTP} 
                        autoFocus 
                        OTPLength={4} 
                        otpType="number" 
                        disabled={false} 
                        secure={false} 
                        className="otpWrapper"
                        inputClassName="otpInput"
                    />
                    <button type="submit" disabled={disableBtn}>{disableBtn ? <img src={loader} alt="" width="23px" /> : "Verify Phone"}</button>
                </form>
            }
            {
                !success &&
                <>
                    <p className="auth_section1-content-already">Didn’t receive the link? <span onClick={requestOTP}>Click to resend</span></p>
                    <Link to="/auth/login" className="link"><p className="auth_section1-content-already"><img src={back} alt="" className="auth_section1-content-already-arrow" />Back to Login</p></Link>
                </>
            }
        </div>
    )
}

export default Verification;