import {Row, Col} from 'react-bootstrap';
import Balance from '../Balance';
import Request from '../Request';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import wave from '../../images/svg/wave.svg';
import check from '../../images/svg/check.svg';
import arrow from '../../images/svg/arrow.svg';
import checked from '../../images/svg/checked.svg';


function Home ({userData}) {
    const [progress, setProgress] = useState(0);

    useEffect(()=>{
        let prog = 0;
        if(userData?.user?.profile_picture!==null){
            prog = prog + 1
        }
        if(userData?.user?.verification?.bank_verification?.status!=="pending"){
            prog = prog + 1
        }
        if(userData?.user?.verification?.employment_verification?.status!=="pending"){
            prog = prog + 1
        }
        if(userData?.user?.verification?.identity_verification?.status!=="pending"){
            prog = prog + 1
        }
        if(userData?.user?.verification?.address_verification?.status!=="pending"){
            prog = prog + 1
        }
        setProgress(prog)
    }, [userData])

    return (
        <div className='home'>
            {console.log(userData)}
            <Row>
                <Col lg={7}>
                    <div className='home_box1'>
                        <div className='home_box1-top'>
                            <h4>Hello, {userData?.user?.first_name}</h4>
                            <img src={wave} alt="" />
                        </div>
                        <div className='home_box1-progress'>
                            <p className='text1'>Complete your verification</p>
                            <p className='text2'>{progress} of 5</p>
                        </div>
                        <Link to="/settings/profile" className='link'>
                            <div className='home_box1-item'>
                                <img src={userData?.user?.profile_picture===null?check:checked} alt="" />
                                <p>Update your profile information</p>
                                <img src={arrow} alt="" className='arrow' />
                            </div>
                        </Link>
                        <Link to="/settings/verification/bank" className='link'>
                            <div className='home_box1-item'>
                                <img src={userData?.user?.verification?.bank_verification?.status==="pending"?check:checked} alt="" />
                                <p>Verify your bank information</p>
                                <img src={arrow} alt="" className='arrow' />
                            </div>
                        </Link>
                        <Link to="/settings/verification/employment" className='link'>
                            <div className='home_box1-item'>
                                <img src={userData?.user?.verification?.employment_verification?.status==="pending"?check:checked} alt="" />
                                <p>Verify Employment Information</p>
                                <img src={arrow} alt="" className='arrow' />
                            </div>
                        </Link>
                        <Link to="/settings/verification/identity" className='link'>
                            <div className='home_box1-item'>
                                <img src={userData?.user?.verification?.identity_verification?.status==="pending"?check:checked} alt="" />
                                <p>Verify your Identity</p>
                                <img src={arrow} alt="" className='arrow' />
                            </div>
                        </Link>
                        <Link to="/settings/verification/address" className='link'>
                            <div className='home_box1-item'>
                                <img src={userData?.user?.verification?.address_verification?.status==="pending"?check:checked} alt="" />
                                <p>Verify your Address</p>
                                <img src={arrow} alt="" className='arrow' />
                            </div>
                        </Link>
                    </div>
                </Col>
                <Col lg={5}>
                    <Balance />
                    <Request userData={userData} />
                </Col>
            </Row>
        </div>
    )
}

export default Home;