import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getLoanSummary, updateLoan } from "../../../lib/data";
import { toast } from "react-toastify";
import { formatMoney } from "../../../lib/formatMoney";

import back from '../../../images/svg/back.svg';
import star from '../../../images/svg/star-green.svg';
import info from '../../../images/svg/info.svg';


function LoanSummary () {
    const params = useParams();
    const [type, setType] = useState('0');
    const [summary, setSummary] = useState({});

    let reasons = {}
    try{
        let data = sessionStorage.getItem('reasons');
        reasons = JSON.parse(data).data;
        console.log(reasons)
    }catch{}

    useEffect(()=>{
        setType(params.type)
        let summaryId = sessionStorage.getItem('summaryId')
        fetchSummary(summaryId)
        if(params.type==='0'){
            updateLoanType('decline', summaryId)
        } else {
            updateLoanType('approve', summaryId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchSummary = (id) => {
        getLoanSummary(id)
            .then((response) => response.data)
            .then((res) => {
                // console.log(res)
                if (res.success === true) {
                    // toast.success(res.message);
                    setSummary(res.data.summary)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                console.log('error')
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const updateLoanType = (loanType, id) => {
        let payload = JSON.stringify({type: loanType})
        updateLoan(id, payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    if(loanType==="disburse"){
                        setType('2')
                    }
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                console.log('error updating loan type')
                if(err.response){
                    // toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const disburseLoan = () => {
        let summaryId = sessionStorage.getItem('summaryId')
        updateLoanType('disburse', summaryId)
    }

    return (
        <div className="home">
            <Row>
                <Link to="/loans" className="link">
                    <div className="loans_request-back">
                        <img src={back} alt=""/>
                        <p>Loans</p>
                    </div>
                </Link>
                <Col md={5}>
                    <h5>{`Loan ${type===0?'Request':'Summary'}`}</h5>
                    {type===1 && <p style={{maxWidth:'300px'}}>We have successfully reviewed the information. Please go over the details before clicking Continue</p>}
                </Col>
                <Col md={6}>
                    {
                        type==='1' ?
                        <div className="loans_request">
                            <p className="loans_request-text4">Approved Loan Amount <span>{formatMoney.format(summary?.approvedAmount ? summary?.approvedAmount : 0)}</span></p>
                            <p className="loans_request-text4">Monthly Interest rate <span>{summary?.monthlyIntrestRate}</span></p>
                            <p className="loans_request-text4">Monthly Interest amount <span>{formatMoney.format(summary?.monthlyInterestAmount ? summary?.monthlyInterestAmount : 0)}</span></p>
                            <p className="loans_request-text4">Loan Tenure <span>{summary?.loanTenure} month(s)</span></p>
                            <p className="loans_request-text4">Monthly Repayment <span>{formatMoney.format(summary?.monthlyRepayment ? summary?.monthlyRepayment : 0)}</span></p>
                            <p className="loans_request-text4">Total Repayment <span>{formatMoney.format(summary?.totalRepayment ? summary?.totalRepayment : 0)}</span></p>
                            <button className="loans_request-btn1" onClick={disburseLoan}>Continue</button>
                        </div>
                        :
                        type==='0'?
                        <div className="loans_request">
                            <div className="loans_request-info">
                                <img src={info} alt="" />
                            </div>
                            <p className="loans_request-text1">Your loan request was declined, please try again later</p>
                            <p className="loans_request-text3">Reasons</p>
                            <ul style={{marginLeft:'-15px'}}>
                                {reasons?.totalActiveLoans && <li className="loans_request-text2">{reasons?.totalActiveLoans}</li>}
                                {reasons?.totalOverdue && <li className="loans_request-text2">{reasons?.totalOverdue}</li>}
                                {reasons?.minimumAverageMonthlyIncome && <li className="loans_request-text2">{reasons?.minimumAverageMonthlyIncome}</li>}
                                {reasons?.minimumMonthlySittingBalance && <li className="loans_request-text2">{reasons?.minimumMonthlySittingBalance}</li>}
                            </ul>
                            {/* 
                            <p className="loans_request-text2">Average income is less than <span>250,000</span></p>
                            <p className="loans_request-text2">Average income is less than <span>250,000</span></p>
                            <p className="loans_request-text2">Average income is less than <span>250,000</span></p> */}
                            <Link to="/loans/request" className="link"><button className="loans_request-btn1">Try Again</button></Link>
                            <Link to="/" className="link"><button className="loans_request-btn2">Go To Home</button></Link>
                        </div>
                        :
                        <div className="loans_request">
                            <img src={star} alt="" style={{marginBottom:"20px"}} />
                            <h5>Loan Disbursed</h5>
                            <p className="loans_request-text2">Your loan has been disbursed. You will receive a confirmation shortly</p>
                            <Link to="/" className="link"><button className="loans_request-btn1">Go to Home</button></Link>
                        </div>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default LoanSummary;