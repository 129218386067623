import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SignupAction } from "../../lib/auth";

import loader from '../../images/svg/loader.svg';
import hide from '../../images/hide.png';
import show from '../../images/show.png';

function Signup () {
    const history = useNavigate();
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        password: ''
    });
    const [passwordValid, setPasswordValid] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [showNew, setShowNew] = useState(false);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })

        if(e.target.name==="password"){
            const passwordWarning = document.getElementById("password-warning");
            var hasUpperCase = /[A-Z]/.test(e.target.value);
            var hasLowerCase = /[a-z]/.test(e.target.value);
            var hasNumbers = /\d/.test(e.target.value);
            if (hasUpperCase && hasLowerCase && hasNumbers) {
                passwordWarning.innerHTML = "";
                setPasswordValid(true);
            } else {
                passwordWarning.innerHTML = "<p>Password must be a minimum 8 characters and contain at least one uppercase letter, one lower case letter and a number</p>";
                setPasswordValid(false);
            }
            
            
        }
    }

    const registerUser = (e) => {
        e.preventDefault();
        if(passwordValid){
            if(data.mobile_number.length===11){
                setDisableBtn(true)
                let payload = JSON.stringify(data);
                SignupAction(payload)
                    .then((response) => response.data)
                    .then((res) => {
                        setDisableBtn(false)
                        if (res.status === true) {
                            toast.success(res.message)
                            history(`/auth/verification/${data.mobile_number}`)
                        } else {
                            toast.error(res.message)
                        }
                    })
                    .catch((err) => {
                        setDisableBtn(false)
                        if(err.response){
                            toast.error(err.response.data.message);
                        } else{
                            if(err.message === "timeout of 15000ms exceeded"){
                                toast.error('Request timed out, please try again!')
                            } else if (err.message==="Network Error"){
                                toast.error('Network error, please make sure you are connected to the internet!')
                            } else{
                                toast.error("Your request can't be processed at this time, please try again later!");
                            }
                        }
                    });
            } else {
                toast.error('Phone number should be 11 characters!')
            }
        } else {
            toast.error('Make sure your password has the correct combination!')
        }
        console.log(data)
    }
    return(
        <div className="auth_section1-content">
            <h2>Sign Up</h2>
            <p className="auth_section1-content-topP">Sign up to get a loan</p>
            <form onSubmit={registerUser}>
                <div>
                    <label>First Name</label><br/>
                    <input type="text" required placeholder='Enter your first name' name="first_name" onChange={handleChange} />
                </div>
                <div>
                    <label>Last Name</label><br/>
                    <input type="text" required placeholder='Enter your last name' name="last_name" onChange={handleChange} />
                </div>
                <div>
                    <label>Email address</label><br/>
                    <input type="email" required placeholder='Enter your email' name="email" onChange={handleChange} />
                </div>
                <div>
                    <label>Phone Number</label><br/>
                    <input type="text" required placeholder='Enter your phone number' name="mobile_number" onChange={handleChange} />
                </div>
                <div className='password-div'>
                    <label>Password</label><br/>
                    <input type={showNew?"text":'password'} required autoComplete='new-password' placeholder='Create a password' name="password" onChange={handleChange} />
                        <img src={showNew ? show:hide} alt="" className='password-div-eye' onClick={()=>setShowNew(!showNew)} />
                    <p className="auth_section1-content-warn" id="password-warning"></p>
                </div>
                <button type="submit" disabled={disableBtn}>{disableBtn ? <img src={loader} alt="" width="23px" /> : "Get Started"}</button>
                <p className="auth_section1-content-already">Already have an account? <Link to="/auth/login" className="link"><span>Log in</span></Link></p>
            </form>
        </div>
    )
}

export default Signup;