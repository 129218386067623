import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { verifyAccount } from '../../../../lib/auth'
// import pdfIcon from "../../../../images/svg/settings/pdf.svg";
// import upload from "../../../../images/svg/settings/upload.svg";
import selectArrow from "../../../../images/svg/select-arrow.svg";

const Identity = ({values, reloadVerification}) => {
  const [showTypeOptions, setShowTypeOptions] = useState(false);
  const [data, setData] = useState({
    id_type: values?.identity_verification?.id_type || '',
    id_number: values?.identity_verification?.id_number || '',
    verification_type: "identity",
  })

  const handleChange = (e) => {
    setData({
    ...data,
    [e.target.name] : e.target.value
   })
  }

  const handleSelectChange = (name, val) => {
    if(val === "DRIVERS LICENSE") val= 'drivers_license'
    setData({
        ...data,
        [name]: val.toLowerCase()
    })
  }

const handleSubmit = (e) => {
  e.preventDefault();

  if(data?.id_type && data?.id_number && data?.verification_type ){
    verifyAccount(data)
    .then((response) => response.data)
    .then((res) => {
        if (res.status === true) {
            toast.success(res.message)
            reloadVerification()
        } else {
            toast.error(res.message)
        }
    })
    .catch((err) => {
        if(err.response){
            toast.error(err.response.data.message);
        } else{
            if(err.message === "timeout of 15000ms exceeded"){
                toast.error('Request timed out, please try again!')
            } else if (err.message==="Network Error"){
                toast.error('Network error, please make sure you are connected to the internet!')
            } else{
                toast.error("Your request can't be processed at this time, please try again later!");
            }
        }
    });
  }else{
    toast.error('Fill all input fields!!!')
  }
}

  return (
    <>
      <div className='verify'>
        <h5> Identity Verification </h5>
        <p className='subTitle'>Verify your identity information</p>
      </div>

    <form onSubmit={handleSubmit}>
        
        <div className='verify_input'>
        <label> Select Identification Type </label>
            <div className="select">
                <input type="text" required style={{cursor:'pointer', textTransform:'uppercase'}} id='id_type' value={data.id_type} placeholder="Identification Type" readOnly name="id_type" onClick={()=>setShowTypeOptions(!showTypeOptions)}/>
                { showTypeOptions &&
                    <div className="select_options">
                        {
                          ["NIN", "BVN", "CAC", "DRIVERS LICENSE"].map((item, idx)=>(
                              <p 
                                className={data.id_type===item?"select_options_selected":""} 
                                key={idx} 
                                onClick={()=>{
                                    setShowTypeOptions(false); 
                                    handleSelectChange("id_type", item)
                                }}
                              >
                                  {item}
                              </p>
                          )) 
                        }
                    </div>
                }
                <img src={selectArrow} alt="arrow" className="select_arrow" style={{top:'20px'}} />
            </div>
      </div>

        <div className='verify_input'>
          <label htmlFor="">Document Number </label>
            <input type="text" required placeholder={"Enter Document Number" } value={data?.id_number} name='id_number' onChange={handleChange} />
        </div>

        <button className='verify_button' type='submit'> Submit for Verification</button>
      </form>
    </>
  )
}

export default Identity