import { APIKit } from "./apikit"


let accessToken = '';
try{
    let data = sessionStorage.getItem('tokens');
    accessToken = JSON.parse(data).access.token
} catch (e) {

}

let baseLink = '/demo';
let loanUrl = '/demoloan' 



// loans
export const requestLoans = (payload) => {
    return APIKit.post(`${loanUrl}/request-loan`, payload, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
}

export const getLoanSummary = (id) => {
    return APIKit.get(`${loanUrl}/loan-summary/${id}`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
}

export const updateLoan = (id, payload) => {
    return APIKit.post(`${loanUrl}/update-loan/${id}`, payload, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
}

export const getLoanHistory = () => {
    return APIKit.get(`${loanUrl}/loan-history`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
}

export const makeRepayment = (id, payload) => {
    return APIKit.post(`${loanUrl}/loanrepayment/${id}`, payload, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
}

export const getAnalysis = id => {
    return APIKit.get(`${loanUrl}/analysis/${id}`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
}

export const authenticateSDK = (payload, key) => {
    return APIKit.post(`https://dev.creditchek.africa/v1/auth/sdk/auth`, payload, {
        headers: {
            'token': key,
        }
    })
}




// settings
export const editProfile = (payload) => {
    return APIKit.post(`${baseLink}/settings/update-profile`, payload, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
}

export const changePassword = (payload) => {
    return APIKit.post(`${baseLink}/settings/change-password`, payload, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
}