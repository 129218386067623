
import action from '../images/svg/action.svg';
import bank from '../images/svg/bank.svg';


function Balance () {
    return(
        <div className='home_box2'>
            <div className='home_box2-top'>
                <h5>Your Loan Account</h5>
                <img src={action} alt="" />
            </div>
            <div className='home_box2-main'>
                <div>
                    <p>Current Balance</p>
                    <h4>₦0</h4>
                </div>
                <img src={bank} alt="" />
            </div>
        </div>
    )
}

export default Balance;