import { Col, Modal, Row } from "react-bootstrap";
import Balance from "../../Balance";
import Request from "../../Request";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAnalysis, getLoanHistory } from "../../../lib/data";
import { toast } from "react-toastify";
import { formatMoney } from "../../../lib/formatMoney";

import upload from '../../../images/svg/upload.svg';
import emptyShell from '../../../images/svg/emptyShell.svg';
import file from '../../../images/svg/file.svg';
import view from '../../../images/svg/view.svg';
import payment from '../../../images/icons8-payment-50.png';
import {FaEllipsisV} from "react-icons/fa"


function Main ({userData}) {
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const months=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [page, setPage] = useState(0);
    const [actionId, setActionId] = useState("");
    const [actionView, setActionView] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(()=>{
        fetchHistory();
        // eslint-disable-next-line
    },[])

    function paginate (arr, size) {
        return arr.reduce((acc, val, i) => {
          let idx = Math.floor(i / size)
          let page = acc[idx] || (acc[idx] = [])
          page.push(val)
      
          return acc
        }, [])
    }

    const fetchHistory = () => {
        getLoanHistory()
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                if (res.success === true) {
                    if(res?.data?.length > 0){
                        setHistory(paginate(res?.data.reverse(), 10)); // 10 per page
                    }
                    else {
                        setHistory([]);
                    }
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                console.log('error')
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const makeRepayment = (item) => {
        if(item.status!=='approved' || item.loanRepayments.length <  1){
            toast.info('No repayment for this loan!')
        } else {
            navigate(`/loans/repayment/${item.id}`)
        }
    }

    const runView = (id) => {
        getAnalysis(id)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                if (res.success === true) {
                    setShow(true);
                    setActionView(res.data)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                console.log('error')
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const movePage = (type) => {
        if(type==='prev' && page>0){
            setPage(page-1);
        }
        if(type==='next' && page<(history.length - 1)){
            setPage(page+1)
        }
    }

    return (
        <div>
            <div className='home'>
                <Row>
                    <Col>
                        <Balance/>
                    </Col>
                    <Col>
                        <Request userData={userData}/>
                    </Col>
                </Row>
            </div>
            <div className='loans_table'>
                <h5 className='loans_table-title'>Loan History</h5>
                {
                    history.length < 1 ?
                    <div className='loans_table-empty'>
                        <img src={emptyShell} alt="" />
                        <h5>No Loan History Available</h5>
                        <p>Gain financial freedom through our robust credit products!</p>
                        <Link to="/loans/request" className="link">
                            <button><img src={upload} alt="" />Request a loan</button>
                        </Link>
                    </div>
                    :
                    <table>
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>Loan Amount</th>
                                <th>Date</th>
                                <th>Loan Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                history?.[page]?.map((item)=>(
                                    <tr>
                                        <td className='td1'>
                                            <img src={file} alt="" />
                                            <p>Loan request</p>
                                        </td>
                                        <td className='td2'>{formatMoney.format(item.amount)}</td>
                                        <td className='td2'>{new Date(item.createdAt).getDate()} {months[new Date(item.createdAt).getMonth()]} {new Date(item.createdAt).getFullYear()} {formatAMPM(new Date(item.createdAt))}</td>
                                        <td>
                                            <div className={item.status==='approved'?'td4':'td3'} style={{textTransform:'capitalize'}}>
                                                <span></span>
                                                <p>{item.status==='pending'?'Cancelled':item.status}</p>
                                            </div>
                                        </td>
                                        <td style={{position: 'relative'}}>
                                            <FaEllipsisV
                                                style={{cursor:'pointer'}}
                                                onClick={()=>setActionId(item.id)}
                                            />
                                            {
                                                actionId===item.id &&
                                                <div className='td5'>
                                                    <p onClick={()=>makeRepayment(item)}><img src={payment} alt="" style={{width:'18px', margin:'-2px 5px 0 0'}} />Make Payment</p>
                                                    <p onClick={()=>runView(item.id)}><img src={view} alt="" style={{width:'18px', margin:'-2px 5px 0 0'}} />View</p>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                }
                {
                    history?.length>1 &&
                    <div className='loans_table-pagination'>
                        <p onClick={()=>movePage('prev')}>Previous</p>
                        {/* <p>Page {page+1}</p> */}
                        <p onClick={()=>movePage('next')}>Next</p>
                    </div>   
                }
            </div>
            <Modal show={show} onHide={()=>setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <div style={{margin: "20px"}}>
                    {
                        actionView?.length<1 ?
                        <h1>No analysis data yet</h1>
                        :
                        <h1>No analysis data yet</h1>
                        // <div>
                            
                        // </div>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default Main;