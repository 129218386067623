import {useState} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginAction } from '../../lib/auth';

import loader from '../../images/svg/loader.svg';
import hide from '../../images/hide.png';
import show from '../../images/show.png';


function Login () {
    const history = useNavigate();
    const [data, setData] = useState({
        mobile_number: '',
        password: ''
    });
    const [disableBtn, setDisableBtn] = useState(false);
    const [showNew, setShowNew] = useState(false);

    
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const doLogin = (e) => {
        setDisableBtn(true);
        e.preventDefault()
        let payload = JSON.stringify(data);
        LoginAction(payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                setDisableBtn(false);
                if (res.status === true) {
                    toast.success(res.message);
                    sessionStorage.setItem(
                        'token', 
                        JSON.stringify({
                            accessToken: res?.data?.tokens?.access?.token,
                            refreshToken: res?.data?.tokens?.refresh?.token,
                        }))
                    sessionStorage.setItem('user', JSON.stringify(res?.data?.user))
                    sessionStorage.setItem('t', JSON.stringify(res?.data?.tokens))
                    history('/')
                    sessionStorage.setItem('tokens', JSON.stringify(res.data.tokens))
                    sessionStorage.setItem('full_name', `${res.data.user.first_name} ${res.data.user.last_name}`)
                    sessionStorage.setItem('email', `${res.data.user.email}`)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setDisableBtn(false);
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div className="auth_section1-content">
            <h2>Log In</h2>
            <p className="auth_section1-content-topP">Welcome back! Please enter your details.</p>
            <form onSubmit={doLogin} autoComplete='off'>
                <div>
                    <label>Phone Number</label><br/>
                    <input type="text" placeholder='Enter your phone number' name='mobile_number' onChange={handleChange} />
                </div>
                <div className='password-div'>
                    <label>Password</label><br/>
                    <input type={showNew?"text":'password'} autoComplete='new-password' placeholder='Enter your password' name='password' onChange={handleChange} />
                        <img src={showNew ? show:hide} alt="" className='password-div-eye' onClick={()=>setShowNew(!showNew)} />
                </div>
                <div className="auth_section1-content-remember">
                    <label className="rem">Remember me
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                    </label>
                    <Link to="/auth/forgot-password" className='link' style={{marginLeft: 'auto'}}>
                        <p className="auth_section1-content-remember-forgot">Forgot Password</p>
                    </Link>
                </div>
                <button type="submit" disabled={disableBtn}>{disableBtn ? <img src={loader} alt="" width="23px" /> : "Sign In"}</button>
                <p className="auth_section1-content-already">Don’t have an account? <Link to="/auth/signup" className='link'><span>Sign Up</span></Link></p>
            </form>
        </div>
    )
}

export default Login;