import Signup from '../components/auth/Signup';
import Login from '../components/auth/Login';
import ForgotPassword from '../components/auth/ForgotPassword';
import ResetPassword from '../components/auth/ResetPassword';
import Verification from '../components/auth/Verification';
import CreatePin from '../components/auth/CreatePin';
import {Routes, Route, Navigate} from "react-router-dom";

import stars from '../images/svg/auth/stars.svg';
import avatar from '../images/svg/auth/avatar-group.svg';


function Auth () {
    return(
        <div className="auth">
            <div className="auth_section1 hideScroll">
                <Routes>
                    <Route path="/" element={<Navigate to="/auth/login" />} />
                    <Route path="/login" element={<Login/>} />
                    <Route path="/signup" element={<Signup/>} />
                    <Route path="/forgot-password" element={<ForgotPassword/>} />
                    <Route path="/reset-password/:otp_code/:mobile_number" element={<ResetPassword/>} />
                    <Route path="/verification/:mobile_number" element={<Verification/>} />
                    <Route path="/create-pin/:mobile_number" element={<CreatePin/>} />
                </Routes>
                <p className="auth_section1-copy">&copy; CreditChek Demo Loans Company</p>
            </div>
            <div className="auth_section2">
                <div className="auth_section2-container">
                    <img src={stars} alt="" className="auth_section2-container-star" />
                    <h1>Quick instant loans for business and personal use</h1>
                    <p>Get your decision in 5mins.</p>
                    <p>Assess, Approve, Disburse, Repay, and Repeat!</p>
                    <div className="auth_section2-container-avatar">
                        <img src={avatar} alt="" />
                        <p>Join 20,000+ users</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth;