import { useState } from 'react';
import OTPInput from "otp-input-react";
import { SendCreatedPin } from '../../lib/auth';
import { useNavigate, useParams } from 'react-router-dom';
import {toast} from 'react-toastify';

import mark from '../../images/svg/auth/mark.svg';
import loader from '../../images/svg/loader.svg';


function Verification () {
    const history = useNavigate();
    const params = useParams();
    const [OTP, setOTP] = useState('')
    const [disableBtn, setDisableBtn] = useState(false);

    const doCreatePin = (e) => {
        setDisableBtn(true);
        e.preventDefault()
        let payload = JSON.stringify({
            pin: OTP,
            mobile_number: params.mobile_number
        });
        SendCreatedPin(payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                setDisableBtn(false);
                if (res.status === true) {
                    toast.success(res.message);
                    history('/auth/login')
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setDisableBtn(false);
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }
    
    return(
        <div className="auth_section1-content">
            <div className="text-center">
                <img src={mark} alt="" className="auth_section1-content-topImg" />
                <h3>Create Secure Pin</h3>
                <p className="auth_section1-content-topP">Create your secure pin</p>
            </div>
            <form onSubmit={doCreatePin}>
                <>
                    <OTPInput 
                        value={OTP} 
                        onChange={setOTP} 
                        autoFocus 
                        OTPLength={4} 
                        otpType="number" 
                        disabled={false} 
                        secure={false} 
                        inputStyles={{width:'60px', margin:'30px 15px 0', height:'60px', fontSize: '3em'}}
                        style={{display:'flex', justifyContent: 'center'}}
                    />
                    <button type="submit" disabled={disableBtn} >{disableBtn ? <img src={loader} alt="" width="23px" /> : "Continue"}</button>
                </>
            </form>
        </div>
    )
}

export default Verification;