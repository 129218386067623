import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getLoanSummary, makeRepayment } from "../../../lib/data";
import { toast } from "react-toastify";
import { formatMoney } from "../../../lib/formatMoney";

import back from '../../../images/svg/back.svg';
import success from '../../../images/svg/payment-success.svg';
import loader from '../../../images/svg/loader.svg';


function LoanRepayment () {
    const history = useNavigate();
    const params = useParams();
    const [details, setDetails] = useState({});
    const [selected, setSelected] = useState('next');
    const [disableBtn, setDisableBtn] = useState(false)

    useEffect(()=>{
        fetchSummary(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSummary = (id) => {
        getLoanSummary(id)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                if (res.success === true) {
                    // toast.success(res.message);
                    setDetails(res.data)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                console.log('error')
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const makePayment = () => {
        setDisableBtn(true);
        let payload = JSON.stringify({
            payment_type: selected ==='full'?"loan_amount":"repayment_amount"
        })
        makeRepayment(params.id, payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                setDisableBtn(false);
                if (res.success === true) {
                    toast.success(res.message);
                    history('/loans')
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setDisableBtn(false);
                console.log('error')
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return (
        <div className="home">
            <Row>
                <Link to="/loans" className="link">
                    <div className="loans_request-back">
                        <img src={back} alt=""/>
                        <p>Loans</p>
                    </div>
                </Link>
                <Col md={5}>
                    <h5>Loan Repayment</h5>
                </Col>
                <Col md={6}>
                    {
                        true ?
                        <div className="loans_request">
                            <h5>How much do you want to pay?</h5>
                            <div className="loans_request-radioButtons">
                                <p>
                                    <input type="radio" id="test1" name="radio-group" checked={selected==="next"} onClick={()=>setSelected('next')} />
                                    <label for="test1">Next repayment</label>
                                </p>
                                <h5>{formatMoney.format(details?.summary?.monthlyRepayment ? details?.summary?.monthlyRepayment : 0)}</h5>
                                <p>
                                    <input type="radio" id="test2" name="radio-group" checked={selected==="full"} onClick={()=>setSelected('full')} />
                                    <label for="test2">Full repayment</label>
                                </p>
                                <h5>{formatMoney.format(details?.summary?.totalRepayment ? details?.summary?.totalRepayment : 0)}</h5>
                                <p>
                                    <input type="radio" id="test3" name="radio-group" checked={selected==="other"} onClick={()=>setSelected('other')} />
                                    <label for="test3">Other amount</label>
                                </p>
                                <div style={{marginTop:'-10px'}}>
                                    <label>Input Amount</label><br/>
                                    <input type="number" placeholder="Input amount" />
                                </div>
                            </div>
                            <button className="loans_request-btn1" onClick={makePayment} disabled={disableBtn}>{disableBtn ? <img src={loader} alt="" width="23px" /> : "Make Repayment"}</button>
                        </div>
                        :
                        <div className="loans_request">
                            <img src={success} alt="" style={{margin:"20px auto", display:'block'}} />
                            <p className="loans_request-text2 text-center">Your payment has been succesfully received</p>
                            <button className="loans_request-btn1">Go to Home</button>
                        </div>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default LoanRepayment;