import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
    const user = sessionStorage.getItem('user');
    return user;
    // return false;
};

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/auth" />;
};

export default ProtectedRoutes;