import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { getAllBankLogo, verifyAccount } from '../../../../lib/auth'
import TypeSelect from "../../../Form/TypeSelect";

const BankInformation = ({values, reloadVerification}) => {
  const [ngBanks, setNgBanks] = useState([]);
  const [data, setData] = useState({
    bvn: "",
    bank_name: '',
    bank_code: '',
    account_number: '',
    verification_type: 'bank'
  })
  
  useEffect(()=>{
    getAllBankLogo()
    .then(resp=>{
        setNgBanks(resp.data);
    })
    setData({
      ...data,
      bvn: values?.bank_verification?.bvn || "",
      bank_name: values?.bank_verification?.bank_name || "",
      account_number: values?.bank_verification?.account_number || "",
      bank_code: values?.bank_verification?.bank_code || '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])
  
  const handleChange = (e) => {
    setData({
    ...data,
    [e.target.name] : e.target.value
   })
  }

  const handleSelect = (item) => {
    let bankCode = ngBanks.find(o => o.name === item)?.code;
    setData({
      ...data,
      bank_name : item,
      bank_code: bankCode
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(data?.bvn && data?.bank_name && data?.account_number){
      verifyAccount(data)
      .then((response) => response.data)
      .then((res) => {
          if (res.status === true) {
              toast.success(res.message)
              reloadVerification()
          } else {
              toast.error(res.message)
          }
      })
      .catch((err) => {
          if(err.response){
              toast.error(err.response.data.message);
          } else{
              if(err.message === "timeout of 15000ms exceeded"){
                  toast.error('Request timed out, please try again!')
              } else if (err.message==="Network Error"){
                  toast.error('Network error, please make sure you are connected to the internet!')
              } else{
                  toast.error("Your request can't be processed at this time, please try again later!");
              }
          }
      });
    }else{
      toast.error('Fill all input fields!!!')
    }
  }
  
  return (
    <>
      <div className='verify'>
        <h5> Bank Information</h5>
        <p className='subTitle'>Update your bank information here</p>
      </div>

    <form onSubmit={handleSubmit}>
        <div className='verify_input'>
          <label> Bank Verification Number </label>
          <div className='input'>
            <input type="text" required placeholder={'Enter BVN'} name='bvn' value={data?.bvn} onChange={handleChange} />
          </div>
            
        </div>

        <div className='verify_input'>
          <label htmlFor=""> Bank Name </label>
            <div >
              <TypeSelect 
                  placeholder={data?.bank_name || "Select Bank"}
                  options={ngBanks}
                  arrayType={"countryObject"}
                  filled={true}
                  selectChange={(item, id)=>handleSelect(item)}
              />
            </div>
           
        </div>

        <div className='verify_input'>
          <label htmlFor=""> Account Number </label>
          <div className='input'>
            <input type="text" placeholder={"Enter Account Number"} required name='account_number' onChange={handleChange} />
          </div>
        </div>

        <button className='verify_button' type='submit'> Submit for Verification</button>
      </form>
    </>
  )
}

export default BankInformation