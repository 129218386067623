import React, { useEffect, useState } from 'react'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getSettings } from '../../../lib/auth'
import Address from './subcomponents/Address'
import BankInformation from './subcomponents/BankInformation'
import Employment from './subcomponents/Employment'
import Identity from './subcomponents/Identity'
import checked from '../../../images/svg/checked.svg'

const Verification = () => {
    const [navItem, setNavItem] = useState("bank")
    const [data, setData] = useState([])

    useEffect(() => {
        loadSettings()
    }, [])

    const loadSettings = () => {
        getSettings()
            .then((response) => response.data)
            .then((res) => {
                if (res.status === true) {
                    setData(res?.data?.user?.verification)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }
    
    return (
        <div className='settings_verification'>
            <div className='settings_verification_sidebar'>
                <h5>Settings</h5>

                <Link to='/settings/verification/bank' className="link">
                <div className={navItem==="bank"?"settings_verification_sidebar-items active":"settings_verification_sidebar-items"} onClick={()=>setNavItem('bank')}>
                    { data?.bank_verification?.status === 'verified' ? <img src={checked} alt='icon' /> : <input type="radio" />}
                    <p>Bank Information</p>
                </div>
                </Link>
                <Link to='/settings/verification/employment' className="link">
                <div className={navItem==="employment"?"settings_verification_sidebar-items active":"settings_verification_sidebar-items"} onClick={()=>setNavItem('employment')}>
                    { data?.employment_verification?.status === 'verified' ? <img src={checked} alt='icon' /> : <input type="radio" />}
                    <p>Employment</p>
                </div>
                </Link>
                <Link to='/settings/verification/identity' className="link">
                <div className={navItem==="identity"?"settings_verification_sidebar-items active":"settings_verification_sidebar-items"} onClick={()=>setNavItem('identity')}>
                    { data?.identity_verification?.status === 'verified' ? <img src={checked} alt='icon' /> : <input type="radio" />}
                    <p>Identity</p>
                </div>
                </Link>
                <Link to='/settings/verification/address' className="link">
                <div className={navItem==="address"?"settings_verification_sidebar-items active":"settings_verification_sidebar-items"} onClick={()=>setNavItem('address')}>
                    { data?.address_verification?.status === 'verified' ? <img src={checked} alt='icon' /> : <input type="radio" />}
                    <p>Address</p>
                </div>
                </Link>
            </div>

            <div className='settings_verification_main'>
            <Routes>
                <Route path="/" element={<Navigate to="/settings/verification/bank" />}/>
                <Route path={'/bank'} element={<BankInformation values={data} reloadVerification={()=>loadSettings()} />} />
                <Route path={'/employment'} element={<Employment values={data} reloadVerification={()=>loadSettings()} />} />
                <Route path={'/identity'} element={<Identity values={data} reloadVerification={()=>loadSettings()} />} />
                <Route path={'/address'} element={<Address values={data} reloadVerification={()=>loadSettings()} />} />

            </Routes>

            </div>

            
        </div>
    )
}

export default Verification