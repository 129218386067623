import axios from 'axios';
import TokenService from "./service/token.service";


let baseURL = "https://dev.creditchek.africa/v1";

export const forLogos = axios.create({
  baseURL: "https://api.creditchek.africa/v1/",
  timeout: 15000, // timeout can be increased
  headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin' : '*'
  },
});


export const APIKit = axios.create({
    baseURL,
    timeout: 30000, // timeout can be increased
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
    },
});


  APIKit.interceptors.request.use(
    (config) => {
      const token = TokenService.getSessionAccessToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  APIKit.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
  
      if (originalConfig.url !== "/auth/login" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
  
          try {
            const rs = await APIKit.post("/auth/refresh-token", {
              refreshToken: TokenService.getSessionRefreshToken(),
            });

            // console.log('do refresh', rs)
            
            const accessToken = rs?.data?.data?.access?.token;
            TokenService.updateSessionAccessToken(accessToken);
            
            const refreshToken = rs?.data?.data?.refresh?.token;
            TokenService.updateSessionRefreshToken(refreshToken);
  
            return APIKit(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
  
      return Promise.reject(err);
    }
  );
  
  export default APIKit;

