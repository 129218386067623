import React, { useState } from 'react'
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';
import selectArrow from "../../../../images/svg/select-arrow.svg";
import { verifyAccount } from '../../../../lib/auth';

const Employment = ({values, reloadVerification}) => {
  const [showTypeOptions, setShowTypeOptions] = useState(false);
  const [data, setData] = useState({
    employment_status: values?.employment_verification?.employment_status || '',
    company_name: values?.employment_verification?.company_name  || '',
    monthly_income: values?.employment_verification?.monthly_income || '',
    company_email: values?.employment_verification?.company_email || '',
    date_of_employment: values?.employment_verification?.date_of_employment || '',
    salary_date: values?.employment_verification?.salary_date || '',
    company_address: values?.employment_verification?.company_address || '',
    verification_type: "employment",
  })

  const handleChange = (e) => {
    setData({
    ...data,
    [e.target.name] : e.target.value
   })
  }

  const handleSelectChange = (name, val) => {
    setData({
        ...data,
        [name]: val
    })
}

const handleSubmit = (e) => {
  e.preventDefault();

  if(data?.employment_status && data?.company_name && data?.monthly_income &&
    data?.company_email && data?.date_of_employment && data?.salary_date && data?.company_address){
    verifyAccount(data)
    .then((response) => response.data)
    .then((res) => {
        if (res.status === true) {
            toast.success(res.message)
            reloadVerification()
        } else {
            toast.error(res.message)
        }
    })
    .catch((err) => {
        if(err.response){
            toast.error(err.response.data.message);
        } else{
            if(err.message === "timeout of 15000ms exceeded"){
                toast.error('Request timed out, please try again!')
            } else if (err.message==="Network Error"){
                toast.error('Network error, please make sure you are connected to the internet!')
            } else{
                toast.error("Your request can't be processed at this time, please try again later!");
            }
        }
    });
  }else{
    toast.error('Fill all input fields!!!')
  }
}

  return (
    <>
    <div className='verify'>
      <h5>Employment Information</h5>
      <p className='subTitle'>Input your recent employment information</p>
    </div>

  <form onSubmit={handleSubmit}>
      <div className='verify_input'>
        <label>Employment Status</label>
            <div className="select">
                <input type="text" required style={{cursor:'pointer'}} id='employment_status' value={data.employment_status} placeholder={'Employment status'} readOnly name="employment_status" onClick={()=>setShowTypeOptions(!showTypeOptions)}/>
                { showTypeOptions &&
                    <div className="select_options">
                        {
                          ["Employed", "Unemployed", "Self Employed"].map((item, idx)=>(
                              <p
                                  className={data.employment_status===item?"select_options_selected":""} 
                                  key={idx} 
                                  onClick={()=>{
                                      setShowTypeOptions(false); 
                                      handleSelectChange("employment_status", item)
                                  }}
                              >
                                  {item}
                              </p>
                          )) 
                        }
                    </div>
                }
                <img src={selectArrow} alt="arrow" className="select_arrow" style={{top:'20px'}} />
            </div>
      </div>

      <div className='verify_input'>
        <label htmlFor=""> Company Name </label>
          <input type="text" placeholder={'Enter Company Name'} name='company_name' onChange={handleChange} />
      </div>

      <div className='verify_input'>
        <label htmlFor=""> Monthly Income </label>
          <CurrencyFormat 
              thousandSeparator={true} 
              prefix={'₦'}
              value={data?.monthly_income}
              decimalScale={2}
              placeholder={"(N) Monthly Income"}
              fixedDecimalScale={true}
              allowNegative={false}
              onValueChange={(values) => {
                  setData({
                      ...data,
                      monthly_income: values.value
                  })
              }}
          />
      </div>

      <div className='verify_input'>
        <label htmlFor=""> Employment Email Address </label>
          <input type="text" placeholder= {"Employment Email Address"} value={data.company_email} name='company_email' onChange={handleChange}  />
      </div>

      <div className='verify_input'>
        <label htmlFor=""> Date of Employment </label>
          <input type="date" placeholder= {"Date of Employment"} value={data.date_of_employment} name='date_of_employment' onChange={handleChange}  />
       
      </div>

      <div className='verify_input'>
        <label htmlFor=""> Pay Day </label>
          <input type="date" placeholder={"Input Pay Day"} value={data.salary_date} name='salary_date' onChange={handleChange}  />
      </div>

      <div className='verify_input'>
        <label htmlFor=""> Company Address </label>
          <input type="text" placeholder={"Input company address"} value={data.company_address} name='company_address' onChange={handleChange}  />
      </div>

      <button className='verify_button' type='submit'> Save</button>
    </form>
  </>
  )
}

export default Employment