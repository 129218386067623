import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ResetPasswordAction, VerifyPasswordOtp } from '../../lib/auth';

import back from '../../images/svg/auth/back.svg';
import key from '../../images/svg/auth/key.svg';
import mark from '../../images/svg/auth/mark.svg';
import loader from '../../images/svg/loader.svg';
import hide from '../../images/hide.png';
import show from '../../images/show.png';


function ResetPassword () {
    const params = useParams()
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState({
        password: '',
        confirmPassword: ''
    });
    const [passwordValid, setPasswordValid] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(()=>{
        const passwordWarning = document.getElementById("password-warning");
        if(data.password!==data.confirmPassword){
            passwordWarning.innerHTML = "<p>Password must match!</p>";
            setPasswordValid(false);
        } else {
            passwordWarning.innerHTML = "";
            setPasswordValid(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.confirmPassword])

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })

        const passwordWarning = document.getElementById("password-warning");
        var hasUpperCase = /[A-Z]/.test(e.target.value);
        var hasLowerCase = /[a-z]/.test(e.target.value);
        var hasNumbers = /\d/.test(e.target.value);
        if(e.target.name==="password"){
            if (hasUpperCase && hasLowerCase && hasNumbers) {
                passwordWarning.innerHTML = "";
                setPasswordValid(true);
            } else {
                passwordWarning.innerHTML = "<p>Password must be a minimum 8 characters and contain at least one uppercase letter, one lower case letter and a number</p>";
                setPasswordValid(false);
            } 
        }
    }

    const doPasswordReset = (e) => {
        e.preventDefault();
        setDisableBtn(true)
        if(passwordValid) {
            let payload = JSON.stringify({
                type: "mobile_number",
                mobile_number: params.mobile_number,
                otp_code: params.otp_code
            });
            VerifyPasswordOtp(payload)
                .then((response) => response.data)
                .then((res) => {
                    if (res.status === true) {
                        // toast.success(res.message);
                        mainReset()
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    if(err.response){
                        toast.error(err.response.data.message);
                    } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                });
        } else {
            toast.error('Please make sure your passwords match and meets the correct coditions!')
        }
    }

    const mainReset = () => {
        let payload = JSON.stringify({
            type: "mobile_number",
            mobile_number: params.mobile_number,
            password: data.password
        });
        ResetPasswordAction(payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                setDisableBtn(false);
                if (res.status === true) {
                    toast.success(res.message);
                    setSuccess(true);
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setDisableBtn(false);
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div className="auth_section1-content">
            <div className="text-center">
                <img src={success?mark:key} alt="" className="auth_section1-content-topImg" />
                <h3>{success?'Password reset':'Set new password'}</h3>
                <p className="auth_section1-content-topP">
                    {
                        success ?
                        'Your password has been successfully reset. Click below to log in '
                        :
                        'Your new password must be different to previously used passwords.'
                    }
                </p>
            </div>
            {
                success ?
                <form>
                    <Link to="/auth/login" className='link'><button type="button">Continue to log in</button></Link>
                </form>
                :
                <form onSubmit={doPasswordReset}>
                    <div className='password-div'>
                        <label>Password</label><br/>
                        <input type={showNew?"text":'password'} required autoComplete='new-password' placeholder='Enter your password' name='password' onChange={handleChange} />
                        <img src={showNew ? show:hide} alt="" className='password-div-eye' onClick={()=>setShowNew(!showNew)} />
                    </div>
                    <div className='password-div'>
                        <label>Confirm Password</label><br/>
                        <input type={showConfirm?"text":'password'} required autoComplete='new-password' placeholder='Enter your password' name='confirmPassword' onChange={handleChange} />
                        <img src={showConfirm ? show:hide} alt="" className='password-div-eye' onClick={()=>setShowConfirm(!showConfirm)} />
                        <p className="auth_section1-content-warn" id="password-warning"></p>
                    </div>
                    <button type="submit" disabled={disableBtn}>{disableBtn ? <img src={loader} alt="" width="23px" /> : "Reset Password"}</button>
                    <Link to="/auth/login" className='link'><p className="auth_section1-content-already"><img src={back} alt="" className="auth_section1-content-already-arrow" />Go back to Login</p></Link>
                </form>
            }
        </div>
    )
}

export default ResetPassword;