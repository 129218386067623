import { useState } from 'react';
import { toast } from "react-toastify";
import { ForgotPasswordAction } from '../../lib/auth';
import { Link } from 'react-router-dom';

import loader from '../../images/svg/loader.svg';
import back from '../../images/svg/auth/back.svg';
import key from '../../images/svg/auth/key.svg';
import message from '../../images/svg/auth/message.svg';


function ForgotPassword () {
    const [success, setSuccess] = useState(false);
    const [phone, setPhone] = useState('');
    const [disableBtn, setDisableBtn] = useState(false);
    const [otpCode, setOtpCode] = useState('')

    const doSubmit = (e) => {
        setDisableBtn(true);
        e.preventDefault()
        let payload = JSON.stringify({
            type: "mobile_number",
            mobile_number: phone
        });
        ForgotPasswordAction(payload)
            .then((response) => response.data)
            .then((res) => {
                console.log(res)
                setDisableBtn(false);
                if (res.status === true) {
                    toast.success(res.message);
                    setSuccess(true);
                    setOtpCode(res.data.otp_code)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setDisableBtn(false);
                if(err.response){
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }
    
    return(
        <div className="auth_section1-content">
            <div className="text-center">
                <img src={success?message:key} alt="" className="auth_section1-content-topImg" />
                <h3>{success?'Check your Phone':'Forgot Password'}</h3>
                <p className="auth_section1-content-topP">
                    {
                        success ?
                        `We sent a password reset link to ${phone}`
                        :
                        'No worries, we’ll send you reset instructions.'
                    }
                </p>
            </div>
            <form onSubmit={doSubmit}>
                {
                    !success &&
                    <>
                        <div>
                            <label>Phone Number</label><br/>
                            <input type="text" required placeholder='Enter your phone number' onChange={(e)=>setPhone(e.target.value)} />
                        </div>
                        <button type="submit" disabled={disableBtn}>{disableBtn ? <img src={loader} alt="" width="23px" /> : "Reset Password"}</button>
                    </>
                }
                {
                    success &&
                    <p className="auth_section1-content-already">Didn’t receive the link? <span onClick={doSubmit}>Click to resend</span></p>
                }
                <Link to={success ? `/auth/reset-password/${otpCode}/${phone}` : "/auth/login"} className="link">
                    <p className="auth_section1-content-already"><img src={back} alt="" className="auth_section1-content-already-arrow" />Back to Login</p>
                </Link>
            </form>
        </div>
    )
}

export default ForgotPassword;