import { APIKit, forLogos } from "./apikit"

let baseLink = '/demo'

//signin, login, logout
export const SignupAction = (payload) => {
    return APIKit.post(`${baseLink}/auth/register`, payload)
}

export const RequestCode = (payload) => {
    return APIKit.post(`${baseLink}/auth/request-verify-account`, payload)
}

export const SendVerificationCode = (payload) => {
    return APIKit.post(`${baseLink}/auth/verify-account`, payload)
}

export const SendCreatedPin = (payload) => {
    return APIKit.post(`${baseLink}/auth/create-pin`, payload)
}

export const LoginAction = (payload) => {
    return APIKit.post(`${baseLink}/auth/login`, payload)
}

export const VerifyPasswordOtp = (payload) => {
    return APIKit.post(`${baseLink}/auth/forgot-password/verify-otp`, payload)
}

export const ForgotPasswordAction = (payload) => {
    return APIKit.post(`${baseLink}/auth/forgot-password`, payload)
}

export const ResetPasswordAction = (payload) => {
    return APIKit.post(`${baseLink}/auth/reset-password`, payload)
}

export const RefreshToken = (payload) => {
    return APIKit.post(`${baseLink}/auth/reset-password`, payload)
}

//bank logo
export const getAllBankLogo = () => {
    return forLogos.get(`/digitalassets/bank/get-all`
    )
}

//settings
export const getSettings = () => {
    return APIKit.get(`${baseLink}/settings`)
}

export const verifyAccount = (payload) => {
    return APIKit.post(`${baseLink}/settings/verify-account`, payload)
}