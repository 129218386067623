import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import Password from './Password'
import Profile from './Profile'
import Verification from './Verification'

const Settings = ({userData, loadProfile}) => {
  const [navItem, setNavItem] = useState("verification")
  return (
    <div className='settings'>
      <h3>Settings</h3>
      <div className='settings_nav'>
        <div className="settings_nav-links">
            <Link to="/settings/verification" className="link">
                <div className={navItem==="verification"?"settings_nav-links-item active":"settings_nav-links-item"} onClick={()=>setNavItem('verification')}>
                    <p>Verification</p>
                </div>
            </Link>
            <Link to="/settings/profile" className="link">
                <div className={navItem==="profile"?"settings_nav-links-item active":"settings_nav-links-item"} onClick={()=>setNavItem('profile')}>
                    <p>Profile</p>
                </div>
            </Link>
            <Link to="/settings/password" className="link">
                <div className={navItem==="password"?"settings_nav-links-item active":"settings_nav-links-item"} onClick={()=>setNavItem('password')}>
                    <p>Password & PIn</p>
                </div>
            </Link>
        </div>
      </div>
      <Container>
        <Routes>
            <Route path="/" element={<Navigate to="/settings/verification" />}/>
            <Route path="/verification/*" element={<Verification />} />
            <Route path="/profile/*" element={<Profile userData={userData} loadProfile={loadProfile} />} />
            <Route path="/password/*" element={<Password />} />
        </Routes>
      </Container>
    </div>
  )
}

export default Settings