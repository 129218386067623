import Auth from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter, Route, Routes } from 'react-router-dom'; 
import { ToastContainer } from "react-toastify";

import './scss/main.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-slider/assets/index.css';
import ProtectedRoutes from "./components/ProtectedRoutes";

function App() {
  return (
    <div>
      <ToastContainer theme="colored" />
      <BrowserRouter>
        <Routes>
          <Route element={<ProtectedRoutes/>}>
            <Route path="/*" element={<Dashboard />} />
          </Route>
          <Route path="/auth/*" element={<Auth />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
