import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import {ng_states} from "../../../../constants/lga";
import { verifyAccount } from '../../../../lib/auth';
import SelectInput from '../../../Form/SelectInput';
import TypeSelect from '../../../Form/TypeSelect';

const Address = ({values, reloadVerification}) => {
  const [data, setData] = useState({
    residential_status: values?.address_verification?.residential_status || "",
    street: values?.address_verification?.street || "",
    house_number: values?.address_verification?.house_number || "",
    address: values?.address_verification?.address || "",
    city: values?.address_verification?.city || "",
    lga: values?.address_verification?.lga || "",
    state: values?.address_verification?.state || "",
    verification_type: "address",
});
const [states, setStates] = useState([]);

useEffect(() => {
  setStates(ng_states)
}, [])

const handleSelectChange = (name, val) => {
  setData({
      ...data,
      [name]: val
  })
}

const handleChange = (e) => {
  setData({
  ...data,
  [e.target.name] : e.target.value
 })
}

const handleSubmit = (e) => {
  e.preventDefault();

    verifyAccount(data)
    .then((response) => response.data)
    .then((res) => {
        if (res.status === true) {
            toast.success(res.message)
            reloadVerification()
        } else {
            toast.error(res.message)
        }
    })
    .catch((err) => {
        if(err.response){
            toast.error(err.response.data.message);
        } else{
            if(err.message === "timeout of 15000ms exceeded"){
                toast.error('Request timed out, please try again!')
            } else if (err.message==="Network Error"){
                toast.error('Network error, please make sure you are connected to the internet!')
            } else{
                toast.error("Your request can't be processed at this time, please try again later!");
            }
        }
    });
}

  return (
    <>
    <div className='verify'>
      <h5>Address Information</h5>
      <p className='subTitle'>Input your address information</p>
    </div>

  <form onSubmit={handleSubmit} >
      <div className='verify_input'>
        <label htmlFor=""> Residential Status</label>
          <SelectInput
              placeholder={values?.address_verification?.residential_status || "Residential Status" }
              options={["Temporary Residence", "Family owned", "Rented", "Personally owned", "Employer Provided"]}
              selectChange={(item)=>handleSelectChange("residential_status", item)}
          />
      </div>

      <div className='verify_input'>
        <label> State </label>
        <TypeSelect
          placeholder={values?.address_verification?.state || "State" }
          filled={true}
          options={states}
          arrayType={"countryObject"}
          selectChange={(item, id)=>{handleSelectChange("state", item)}}
        />
      </div>

      <div className='verify_input'>
        <label> LGA </label>
        <input type="text" required placeholder={"LGA" } value={data?.lga} id="lga" name="lga" onChange={handleChange} />
      </div>

      <div className='verify_input'>
        <label htmlFor=""> City </label>
          <input type="text" required placeholder={"City" } value={data?.city} name="city" onChange={handleChange}    />
      </div>

      <div className='verify_input'>
        <label htmlFor=""> Date at Residential Address </label>
          <input type="date" placeholder={"Date at Residential Address " } value={data?.address} name="address" onChange={handleChange} />
      </div>

      <div className='verify_input'>
        <label htmlFor=""> House Number </label>
          <input type="text" required placeholder={"Enter House Number"} value={data?.house_number} name="house_number" onChange={handleChange} />
      </div>

      <div className='verify_input'>
        <label htmlFor=""> Street </label>
          <input type="text" required placeholder={"Street" } value={data?.street} name="street" onChange={handleChange} />
      </div>

      <button className='verify_button' type='submit'> Save</button>
    </form>
  </>
  )
}

export default Address