import React, { useEffect, useState } from 'react';
import { editProfile } from '../../../lib/data';
import { toast } from 'react-toastify';

import avatar from '../../../images/svg/avatar.svg';

const Profile = ({userData, loadProfile}) => {
  const [data, setData] = useState({
    name: '',
    email: '',
    dob: '',
    gender: '',
    status: ''
  })
  const [imgPreview, setImgPreview] = useState(null);

  useEffect(()=>{
    setData({
      ...data,
      name: sessionStorage.getItem('full_name'),
      email: sessionStorage.getItem('email')
    })
    console.log(userData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const doSubmit = (e) => {
    e.preventDefault();
    console.log(data)
    let formData = new FormData();
    if(imgPreview!==null) formData.append('file', imgPreview[0]);
    formData.append('full_name', data.name);
    formData.append('email', data.email);
    if(data.dob!=="") formData.append('date_of_birth', data.dob);
    if(data.gender!=="") formData.append('gender', data.gender);
    if(data.status!=="") formData.append('marital_status', data.status);
    editProfile(formData)
      .then((response) => response.data)
      .then((res) => {
          if (res.status === true) {
              toast.success(res.message);
              loadProfile()
          } else {
              toast.error(res.message)
          }
      })
      .catch((err) => {
        console.log(err)
          if(err.response){
              toast.error(err.response.data.message);
          } else{
              if(err.message === "timeout of 15000ms exceeded"){
                  toast.error('Request timed out, please try again!')
              } else if (err.message==="Network Error"){
                  toast.error('Network error, please make sure you are connected to the internet!')
              } else{
                  toast.error("Your request can't be processed at this time, please try again later!");
              }
          }
      });
  }

  const doPreview = (e) => {
    setImgPreview(e.target.files)
  }

  return (
    <div className='settings_profile'>
      <div className='settings_profile-top'>
        <h5>Profile</h5>
        <p>Update your personal details here.</p>
      </div>
      <div className='settings_profile-item'>
        <div className='settings_profile-item-left'>
          <p className='text1'>Your Photo</p>
          <p className='text2'>This will be displayed on your profile.</p>
        </div>
        <div className='settings_profile-item-right'>
          <div className='settings_profile-item-right-image'>
            <img 
              src={
                imgPreview===null?
                    userData?.user?.profile_picture===null || userData?.user?.profile_picture===undefined || userData?.user?.profile_picture==="" ?
                    avatar
                    :
                    userData?.user?.profile_picture
                  : 
                    URL.createObjectURL(imgPreview[0])
              }
              alt="" 
            />
          </div>
          <input type="file" id="imgupload" accept="image/*" onChange={doPreview} style={{display:"none"}} />
          <p 
            className='settings_profile-item-right-change'
            onClick={()=>{
              let doc = document.getElementById('imgupload');
              doc?.click()
            }} 
          >
            Change Profile Picture
          </p>
        </div>
      </div>
      <form onSubmit={doSubmit}>
        <div className='settings_profile-item'>
          <div className='settings_profile-item-left'>
            <p className='text1'>Full Name</p>
          </div>
          <div className='settings_profile-item-right'>
              <input type="text" defaultValue={data.name} placeholder='Enter your full name' name='name' onChange={handleChange} />
          </div>
        </div>
        <div className='settings_profile-item'>
          <div className='settings_profile-item-left'>
            <p className='text1'>Email address</p>
          </div>
          <div className='settings_profile-item-right'>
              <input type="email" defaultValue={data.email} placeholder='Enter your email address' name='email' onChange={handleChange} />
          </div>
        </div>
        <div className='settings_profile-item'>
          <div className='settings_profile-item-left'>
            <p className='text1'>Date of birth</p>
          </div>
          <div className='settings_profile-item-right'>
              <input type="date" placeholder='Enter your date of birth' name='dob' onChange={handleChange} />
          </div>
        </div>
        <div className='settings_profile-item'>
          <div className='settings_profile-item-left'>
            <p className='text1'>Gender</p>
          </div>
          <div className='settings_profile-item-right'>
            <select name='gender' onChange={handleChange}>
              <option disabled>Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
        </div>
        <div className='settings_profile-item'>
          <div className='settings_profile-item-left'>
            <p className='text1'>Marital Status</p>
          </div>
          <div className='settings_profile-item-right'>
            <select name='status' onChange={handleChange}>
              <option disabled>Select marital status</option>
              <option value="single">Single</option>
              <option value="married">Married</option>
              <option value="divorced">Divorced</option>
            </select>
          </div>
        </div>
        <div className='settings_profile-buttons'>
          <button type="submit" className='submit'>Save</button>
          <button type="button" className='cancel'>Cancel</button>
        </div>
      </form>
    </div>
  )
}

export default Profile