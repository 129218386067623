import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { requestLoans, authenticateSDK } from "../../../lib/data";
import {Helmet} from "react-helmet";
import CurrencyFormat from 'react-currency-format';
import Slider from 'rc-slider';

import back from '../../../images/svg/back.svg';
import loader from '../../../images/svg/loader.svg';

function LoanRequest ({userData}) {
    const [ value, setValue ] = useState(0);
    const [data, setData] = useState({
        amount: '',
        purpose: ''
    });
    const [disableBtn, setDisableBtn] = useState(false);

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const makeRequest = () => {
        let payload = JSON.stringify({
            amount: `${data.amount}`,
            duration: `${value<26 ? '1' : value>26 && value < 51 ? '3' : value>51 && value < 76 ? '6' : '12'}`,
            purpose: data.purpose
        })
        if(data.amount==='' || data.purpose===''){
            toast.error('All fields are compulsory!')
        } else {
            setDisableBtn(true);
            requestLoans(payload)
                .then((response) => response.data)
                .then((res) => {
                    if (res.success === true) {
                        toast.success("Loan request initiated")
                        // handleSDKOpen(res.data.id)
                        SDKAuth(res.data.id)
                        sessionStorage.setItem('summaryId', res.data.id);
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    console.log('error: ', err)
                    if(err.response){
                        toast.error(err.response.data.message);
                    } else{
                        if(err.message === "timeout of 15000ms exceeded"){
                            toast.error('Request timed out, please try again!')
                        } else if (err.message==="Network Error"){
                            toast.error('Network error, please make sure you are connected to the internet!')
                        } else{
                            toast.error("Your request can't be processed at this time, please try again later!");
                        }
                    }
                });
        }
    }

    const SDKAuth = (loanId) => {
        const payload = JSON.stringify({
            email: "madara@yopmail.com",
            password: "Zingzang@#12"
        })
        
        authenticateSDK(payload, "TAhZfsNJPwPT+2GxCQ5DF9jgjWPxQcsvAlCUhHxKjeZ4t/iFEysCY8CASDg8Esjk")
            .then((response) => response.data)
            .then((res) => {
                setDisableBtn(false);
                if (res.success === true) {
                    handleSDKOpen(loanId, res.data.token)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setDisableBtn(false);
                toast.error("An error occured on request authentication!")
            });
    }

    const handleSDKOpen = (loanId, sessionId) => {
        var config = {
            businessId: "6295e8b91a4f510013818fc1",
            key: "TAhZfsNJPwPT+2GxCQ5DF9jgjWPxQcsvAlCUhHxKjeZ4t/iFEysCY8CASDg8Esjk",
            customerName: `${userData?.user?.first_name} ${userData?.user?.last_name}`,
            loanAmount: `${data.amount}`,
            loanId: `${loanId}`,
            appId: '6295e8bacccda600124334c9',
            sessionId,
            onClose: () => {
                console.log('closed widget')
            },
            onComplete: (data) => {
                // action when the widget has shown results
                if (data.data===true) {
                    window.location = '/loans/summary/1'
                } else {
                    sessionStorage.setItem('reasons', JSON.stringify(data))
                    window.location = '/loans/summary/0'
                }
            }
        }

        const connect = new window.Connect(config);
        connect.open();
    }

    return (
        <div className="home">
            <Helmet>
                <script src="https://securedwidget.creditchek.africa/widget.js"></script>
            </Helmet>
            <Row>
                <Link to="/loans" className="link">
                    <div className="loans_request-back">
                        <img src={back} alt=""/>
                        <p>Loans</p>
                    </div>
                </Link>
                <Col md={5}>
                    <h5>Loan Request</h5>
                </Col>
                <Col md={6}>
                    <div className="loans_request">
                        <div>
                            <label>How much do you want to borrow</label><br/>
                            <CurrencyFormat 
                                thousandSeparator={true} 
                                prefix={'₦'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                onValueChange={(values) => {
                                    const {formattedValue, value} = values;
                                    // formattedValue = $2,223
                                    // value ie, 2223
                                    console.log(formattedValue)
                                    setData({
                                        ...data,
                                        amount: value
                                    })
                                }}
                            />
                        </div>
                        <div style={{position:'relative'}}>
                            <label style={{margin:'0 0 10px'}}>Loan Tenure</label><br/>
                            <Slider
                                value={value}
                                step={25}
                                onChange={(value) => setValue(value)}
                                handleStyle={{background: 'white', borderColor: '#9328FF', boxShadow: 'none', marginTop: '-3.5px'}}
                                trackStyle={{background: '#9328FF', height: '7px'}}
                                railStyle={{height: '7px'}}
                            />
                            {
                                value>1 &&
                                <p style={{position:'absolute', top:'0', left:'100px', background:'white', fontSize:'0.8em', padding:'3px', borderRadius:'4px', border:'1px solid #D0D5DD', color:'#344054', fontWeight:'600'}}>
                                    {value<26 ? '1 month' : value>26 && value < 51 ? '3 months' : value>51 && value < 76 ? '6 months' : '12 months'}
                                </p>
                            }
                        </div>
                        <div style={{marginTop:'20px'}}>
                            <label>Purpose of the loan</label><br/>
                            <input type="text" placeholder="Input purpose" name="purpose" onChange={handleChange} />
                        </div>
                        <button className="loans_request-btn1" onClick={makeRequest} disabled={disableBtn}>{disableBtn ? <img src={loader} alt="" width="23px" /> : "Request loan"}</button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default LoanRequest;