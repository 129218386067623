import {Routes, Route} from 'react-router-dom';
import Main from './Main';
import LoanRequest from './LoanRequest';
import LoanSummary from './LoanSummary';
import LoanRepayment from './LoanRepayment';


function Loans ({userData}) {
    return(
        <div>
            <Routes>
                <Route path="/" element={<Main userData={userData} />} />
                <Route path="/request" element={<LoanRequest userData={userData} />} />
                <Route path="/summary/:type" element={<LoanSummary />} />
                <Route path="/repayment/:id" element={<LoanRepayment />} />
            </Routes>
        </div>
    )
}

export default Loans;