

const getSessionRefreshToken = () => {
    const token = JSON.parse(sessionStorage.getItem("token"));
    return token?.refreshToken;
  };

  const updateSessionRefreshToken = (payload) => {
    let token = JSON.parse(sessionStorage.getItem("token"));
    token.refreshToken = payload;
    sessionStorage.setItem("token", JSON.stringify(token));
  };
  
  const getSessionAccessToken = () => {
    const token = JSON.parse(sessionStorage.getItem("token"));
    return token?.accessToken;
  };
  
  const updateSessionAccessToken = (payload) => {
    let token = JSON.parse(sessionStorage.getItem("token"));
    token.accessToken = payload;
    sessionStorage.setItem("token", JSON.stringify(token));
  };
  
  const getUser = () => {
    return JSON.parse(sessionStorage.getItem("user"));
  };
  
  const setUser = (user) => {
    sessionStorage.setItem("user", JSON.stringify(user));
  };
  
  const removeUser = () => {
    sessionStorage.removeItem("user");
  };
  
  const TokenService = {
    getSessionRefreshToken,
    updateSessionRefreshToken,
    getSessionAccessToken,
    updateSessionAccessToken,
    getUser,
    setUser,
    removeUser,
  };
  
  export default TokenService;